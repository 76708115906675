import React, { useContext, useImperativeHandle } from 'react';
import { GlobalContext } from '../context/GlobalState'
//HELPERS
import { arrangeDate } from '../helpers/arrangeDate';
import { formatDni } from '../helpers/formatDni';
import { capitalize } from '../helpers/capitalize';
import { formatCel } from '../helpers/formatCel'
import Avatar from 'react-avatar';
import { useHistory } from 'react-router';
export const User = ({ users, user }) => {
	let history = useHistory()
	return (
		<div onClick={() => {
			users.map((u, index) => {
				if (u.id_usu == user.id_usu){
					history.push(`/usuario/${user.id_usu}`)
				}
			})
		}}
		className='py-3  my-3 hover-press-animation transition cursor-pointer rounded-lg card-shadow  w-full    p-2 text-center  flex items-center  justify-center gap-1  '>
			<div className='	md:w-1/7 w-1/7 select-none text-right	'>
				<Avatar className='text-shadow rounded-full card-shadow font-bold ' name={user.nombrePersona} size="50" />
			</div>
			<div className='	md:w-2/7 ml-2 	md:ml-0 w-4/7  text-left flex flex-col items-center '>
				<p className='font-bold  text-center '>
					{capitalize(user.nombrePersona)}
				</p>
				<span className='	md:invisible 	md:h-0'>
					DNI: <span className='font-bold'>{formatDni(user.documentoPersona)}</span>
				</span>
				
			</div>
			<div className='	md:w-1/7   w-0 invisible 	md:visible'>
				{formatDni(user.documentoPersona)}
			</div>
			<div className='	md:w-2/7 h-full  flex items-center justify-center   w-0 invisible 	md:visible '>
				{user.mailPersona ? <p className='overflow-x-scroll pt-2'>{user.mailPersona}</p> : <p className=' text-red-400 text-shadow-sm'>NO</p>}
			</div>
			<div className='	md:w-1/7  text-center w-1/7  flex  items-center justify-center'>
			<div 
					className='flex text-xs cursor-pointer items-center text-blue hover-text-green transition	select-none'
					
				>
					<i className="pr-1 fas fa-info-circle text-base   "></i> VER
				</div>
			</div>

		</div>
	)
}