import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { GlobalContext } from '../context/GlobalState'
//HELPERS
import { capitalize } from '../helpers/capitalize';
import { arrangeDate } from '../helpers/arrangeDate';
import { formatDni } from '../helpers/formatDni';
import { formatCel } from '../helpers/formatCel';
import { isTokenExpired } from '../helpers/isTokenExpired';
//Custom component
import { InfoItem } from './InfoItem';
import { AccountItem } from './AccountItem';
import { getById } from '../helpers/getById';

export function UserInfo() {
	let history = useHistory();
	const { id } = useParams()
	const { tokenState, usersState } = useContext(GlobalContext)
	const [token, setToken] = tokenState
	const [loading, setLoading] = useState(true)
	const [users, setUsers] = usersState
	const [user, setUser] = useState(null)
	const [cuenta, setCuenta] = useState(null)
	const [accountLoading, setAccountLoading] = useState(true)
	 
	useEffect(async () => {
		console.log(token)
		if (!token || await isTokenExpired(token)){
			history.replace('/login/usuario');
			return
		}
		if (isNaN(id)){
			history.replace('/usuario');
			return
		}
		const usuario = await getById(token, `https://api.sindicarne.com.ar/credencial/${id}`)
		console.log(usuario)
		setUser(usuario)
		const account =  await getById(token, `https://api.sindicarne.com.ar/usuario/${usuario.id_usu}`)
		setCuenta(account)
		account && setAccountLoading(false)
		usuario  && setLoading(false)
	}, [])
	
	
	return (
		user && user.id_usu ?
			<div className='px-10  w-6/7 py-6 fadeIn h-full lg:items-start text-left '>
				<div className='flex w-full flex-col lg:flex-row flex  flex-col justify-between items-center lg:items-start '>
					<div className='w-1/2 flex flex-col items-center justify-center'>
						<div className='pb-8 flex items-center justify-center w-full '>
							<p className='text-center  text-xl font-bold text-gray-800 border-b-2 border-green max-w-min '>USUARIO</p>
						</div>
						<InfoItem icon={'fas fa-user'} description='Nombre' value={capitalize(user.nombrePersona)} />
						<InfoItem icon={'fas fa-id-card'} description='DNI' value={formatDni(user.documentoPersona)} />
						<InfoItem icon={'fas fa-envelope'} description='Email' value={user.mailPersona ? user.mailPersona.toLowerCase() : <p className=' text-red-400 text-shadow-sm'>No está cargado</p>} />
					</div>
					<div className='w-full lg:w-1/2 flex flex-col items-center py-10 lg:py-0'>
					<div className='pb-8 flex items-center justify-center w-full '>
						<p className='text-center  text-xl font-bold text-gray-800 border-b-2 border-green max-w-min '>CUENTA</p>
					</div>
					{cuenta && cuenta.id_usu ? 
						<div>
							<AccountItem user= {user} setUsers = {setUsers} setUser = {setUser} setCuenta={setCuenta} accountId={cuenta.id_usu} type='username' icon={'fas fa-user'} description='Usuario' value={cuenta.nom_usu} />
							<AccountItem user= {user} setUsers = {setUsers} setUser = {setUser} setCuenta={setCuenta} accountId={cuenta.id_usu} type='password' icon={'fas fa-key'} description='Contraseña' value={'**********'} />
							<AccountItem user= {user} setUsers = {setUsers} setUser = {setUser} setCuenta={setCuenta} accountId={cuenta.id_usu} type='email' icon={'fas fa-envelope'} description='Email' value={user.mailPersona ? user.mailPersona.toLowerCase() : <p className=' text-red-400 text-shadow-sm'>No está cargado</p>} />
						</div>
					: accountLoading == true ?
					
						<div className='flex items-center justify-center text-xl w-full text-center p-10'>
							<p className=' w-6 h-6 rounded-full border-l-4 border-t-4 border-r-4 animate-spin border-green'></p>
							<p className='pl-4'>CARGANDO...</p>
						</div>
					
					:
						<p className='text-red-400 text-shadow-sm'>No tiene cuenta</p>
					}
					
					</div>
				</div>
				<Link to='/usuario' className='cursor-pointer py-3 lg:py-5   text-center transition fadeIn transition relative w-full'>
					<i className='-top-2 transition fas fa-angle-double-left absolute  -left-10 right-0 text-blue  hover-text-green font-bold text-shadow-sm  press-animation	 text-4xl lg:text-5xl ' ></i>
					<p className='text-sm lg:text-base text-gray-800 absolute -bottom-0 -left-10 right-0'>Volver</p>
				</Link>
			</div>
		:
			user && user.status ?
				user.status == 404 ?
					<p className='py-10 text-center '>No se encontro lo que se buscaba.</p>
				:	user.status == 400 ?
					<p className='py-10 text-center '>El id ingresado es incorrecto</p>
				:	user.status == 403 ?
					<p className='py-10 text-center '>No autorizado</p>
				:	user.status == 500 ?
					<p className='py-10 text-center '>Hubo un error</p>
				:
					''
			: loading ?
				<div className='flex items-center justify-center text-2xl w-full text-center p-10'>
					<p className=' w-10 h-10 rounded-full border-l-4 border-t-4 border-r-4 animate-spin border-green'></p>
					<p className='pl-4'>CARGANDO...</p>
				</div>
			:
			<div className={`p-10`}>
				<p className='text-red-400 text-shadow-sm text-3xl'>ERROR 500!</p>
				<Link className='text-center flex items-center justify-center p-1 mt-6 rounded border-green border-2' to='/usuario'>Volver</Link>
			</div>
	);
}