export const getAll = async (token, endpoint) => {
	if (!endpoint) return { status: 400, message: 'Parametros incorrectos' }
	
	if (!token ||	 token.ok != true) return { status: 403, message: 'No autorizado' }
	
	return await fetch(endpoint, {
		headers: {
			'Content-Type': 'application/json',
			'x-auth-token': token.token
		}
	})
	.then(response => {
		if(!response.ok) return {status: response.status, message: response.statusText} 
		return response.json()
	})
	.then(data =>  data)
	.catch(error => {
		return {
			status: 500,
			devMessage: error,
			message: 'Error, intente mas tarde'
		}
	})
}
