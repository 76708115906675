export const patchById = async (token, endpoint, data) => {
	if (!data){
		return {
			status: 400,
			message: 'patch data param required'
		}
	}
	const key = Object.keys(data)[0]
	const value = Object.values(data)[0]
	if (!endpoint){
		return {
			status: 400,
			message: 'endpoint param required'
		}
	}
	if (!token ||	 token.ok != true)
		return {
			status: 403,
			message: 'unauthorized'
		}
	return await fetch(endpoint, {
		method: "PATCH",	
		headers: {
			'Content-Type': 'application/json',
			'x-auth-token': token.token
		}	,
		body: JSON.stringify({
			[key]: value
		})
		
	})
	.then(response => {
		if(!response.ok) return {status: response.status, message: response.statusText} 
		return response.json()
	})
	.then(data => { 
		return data
	})
	.catch(error => {
		return {
			status: 500,
			devMessage: error,
			message: 'Error, intente mas tarde'
		}
	})
}