import React, { useContext, useEffect, useState } from 'react'
import { authenticate } from '../helpers/authenticate'
import { useHistory } from "react-router-dom";
import { isTokenExpired } from '../helpers/isTokenExpired';
import { GlobalContext } from '../context/GlobalState';
import { useLocalStorage } from '../hooks/useLocalStorage';

export const Auth = () => {
	let history = useHistory();
	const redirect = history.location.pathname.split('/').reverse()[0] == 'usuario' || history.location.pathname.split('/').reverse()[0] == 'postulante' ? history.location.pathname.split('/').reverse()[0] : 'postulante'
	const { tokenState } = useContext(GlobalContext)
	const [token, setToken] = tokenState
	const [loading, setLoading ] = useState(false)
	const [rememberUser, setRememberUser] = useLocalStorage('rememberUser', false)
	const [user, setUser] = useState({
		username: '',
		password: ''
	})

	const [savedUser, setSavedUser] = useLocalStorage('user', null)

	const [error, setError] = useState('')

	useEffect(async () => {
		if (token && await isTokenExpired(token) == false) { history.replace(`/${redirect}`); return; }
		if (await isTokenExpired(token) == true) setError('')
		savedUser && rememberUser && setUser(savedUser)
	}, [])

	const handleAuth = async (e) => {
		setLoading(true)
		e.preventDefault()
		const auth = await authenticate(user)
		if (auth != null && auth.ok == true){
			rememberUser ? setSavedUser(user) : setSavedUser(null)
			setToken(auth)
			history.replace(`/${redirect}`)
			return
		} else {
			setError(auth.ok == false ? 'acceso denegado': 'error. intente más tarde.')
			setUser({
				username: '',
				password: ''
			})
		}	
		setLoading(false)

	}

	return (
		<div className='my-6 fadeInDown z-0 h-full flex items-center justify-center gap-4 flex-col   p-4 rounded '>
				<p className='border-b-4 border-green font-semibold text-lg text-gray-800'>INICIAR SESIÓN</p>
				<form onChange={() => setError(false)} onSubmit={handleAuth} className='text-gray-900 flex flex-col'>
					<label className='flex flex-col'>
						<span>Usuario </span>
						<input type='text' value={user.username} onChange={(e) => setUser({...user, username:e.target.value})} className='border-2 border-gray-500 outline-none  py-2 text-center focus-border-green' />
					</label>
					<label className='flex flex-col	pt-4'>
					<span>Contraseña </span>
						<input type='password' value={user.password} onChange={(e) => setUser({...user, password:e.target.value})} className='border-2 border-gray-500 outline-none py-2 text-center'  />
					</label>
					<div onClick={() => setRememberUser(!rememberUser)} className=' cursor-pointer w-full flex mt-3 items-center'>
						<input className='' type='checkbox' checked={rememberUser}/>
						<p className='text-sm pl-1 -mt-px '>Recordar credenciales</p>
					</div>
						{loading ?
							<button className={` text-sm w-40 border-green text-center self-center mt-4 px-4 py-2  rounded  text-gray-800  transition border-4 hover:text-gray-800  `}>
								<div className=' flex gap-1 items-center justify-center'>
									<i class="text-xl fas fa-spinner text-green animate-spin "></i>
									<span className=' flex items-center justify-center'>
										CARGANDO
									</span>
								</div>
							</button>
						:
							<button className={`bg-green w-40 border-transparent text-center text-sm self-center mt-4 px-4 py-2  rounded  text-gray-200  transition border-4 hover:text-gray-800 border-transparent hover:bg-transparent hover-border-green `}>
								
									
										CONTINUAR
							</button>}
				</form>
				{error != '' ? <p className='fadeIn transition text-red-400 font-bold text-shadow-sm uppercase text-base'>{error}</p> : ''}
		</div>
	)
}