export const getById = async (token, endpoint) => {
	if (!endpoint){
		return {
			status: 400,
			message: 'Parametros incorrectos'
		}
	}
	const id = endpoint.split('/').reverse()[0]
	if (isNaN(id)){
		return {
			status: 400,
			message: 'El id ingresado es incorrecto'
		}
	}
	if (!token ||	 token.ok != true)
		return {
			status: 403,
			message: 'No autorizado'
		}

	return await fetch(endpoint, {
		headers: {
			'Content-Type': 'application/json',
			'x-auth-token': token.token
		}
	})
	.then(response => {
		console.log(response, 'asdjasdjkasd')

		if(!response.ok) return {status: response.status, message: response.message} 
		return response.json()
	})
	.then(data => data)
	.catch(error => {
		return {
			status: 500,
			devMessage: error,
			message: 'Error, intente mas tarde'
		}
	})
}
