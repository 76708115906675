import React, {useState, useContext} from "react";
import { useHistory, useParams } from "react-router-dom";
import { GlobalContext } from "../context/GlobalState";
import { isTokenExpired } from "../helpers/isTokenExpired";
import { useWindowSize } from '../hooks/useWindowSize';
import { patchById } from "../helpers/patchById";
import { getById } from "../helpers/getById";
export function AccountItem({ user, setUser, setCuenta, accountId, type, description, value, icon }) {
	let history = useHistory()
	const { tokenState, usersState } = useContext(GlobalContext)
	const window = useWindowSize()
	const { id } = useParams()
	const [token, setToken] = tokenState
	const [users, setUsers] = usersState
	const [newValue, setNewValue] = useState(value)
	const [editMode, setEditMode] = useState(false)
	const [error, setError] = useState(false)
	const [hidden, setHidden] = useState(true)
	const [showPassword, setShowPassword] = useState(false)
	const [disabled, setDisabled] = useState(false)
	const [loading, setLoading] = useState(false)
	const [submitMessage, setSubmitMessage] = useState('')
	//validation
	const regex = 
		type == 'username' ?
			/^(?=.*[a-z])[a-zA-Z0-9]{6,}$/
		: type == 'password' ?
			/^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z]{6,}$/
		: type == 'email' ?
			/^[^\s@]+@[^\s@]+\.[^\s@]+$/
		:
			''
	const handleSave = async () => {
		setDisabled(true)
		setLoading(true)
		if (!token || await isTokenExpired(token) == true) { history.replace('/login'); return; }
		console.log('xd')
		const patch = await patchById(token, `https://api.sindicarne.com.ar/usuario/${accountId}`, {[type]: newValue})
		patch && setLoading(false)
		if (patch){
			switch (patch.status){
				case 200:
					setEditMode(false)
					setSubmitMessage(`El ${type == 'username' ? 'usuario' : type == 'password' ? 'contraseña' : type == 'email' && 'email'} ha sido modificado.`)
					setError(false)
					setHidden(true)
					const usuario = await getById(token, `https://api.sindicarne.com.ar/credencial/${id}`)
					setUser(usuario)
					break;
				case 500:
					setError(true)
					setSubmitMessage('Hubo un error. Intente nuevamente más tarde.')
					break;
				case 400:
					setError(true)
					setSubmitMessage('Ingresar valor distinto al actual')
					break;
			}
			
		} else{
			setError(true)
		}
		setTimeout(() => setDisabled(false), 1500)
	}
	return (
		<div className='mb-4 flex flex-col items-center'>
			<div className='fadeIn w-64 card-shadow glass p-2 flex flex-col pb-2  rounded text-gray-700 border-b-2 border-green '>
				<span className='font-bold border-b-2 border-gray-500 rounded flex justify-between '>
					<div>
						<i className={"text-gray-700 " + icon}></i>
						<span className='pl-2'>{description}</span>
					</div>
					
					<div className='flex flex-col gap-6 '>
						<div
							onClick={() => {
								setSubmitMessage('')
								setError(false)
								setShowPassword(false)
								setEditMode(!editMode);								
								(newValue != '') && setHidden(true)								
								editMode == false ? 
									 setNewValue('') 
								: 
									setNewValue(type == 'username' ? user.nom_usu : type == 'password' ? value : type == 'email' && user.mailPersona)}
							} 
							className=' lg:-ml-2 flex text-xs lg:text-sm select-none cursor-pointer hover-text-green transition'							
						>
							<i className={`fas fa-pencil-alt text-lg transition ${editMode && 'text-green'}`}/>
							<p className='pl-1  overflow-hidden'>{window.width > 540 && (editMode   ? 'Cancelar' : 'Editar')}</p>
						</div>
					</div>
				</span>
				<div className='relative flex pt-2'>
					<div className='flex relative '>
						<input type={!showPassword && type == 'password' ? 'password' : 'text'} disabled={ editMode == false ?  true : false } onChange={ (e) => {!e.target.value.match(regex)  || e.target.value.length < 6  || e.target.value == '' ? setHidden(true) : setHidden(false); setNewValue(e.target.value); setSubmitMessage(''); setError(false);} } value={newValue} className=' pl-2 outline-none bg-transparent text-gray-800  w-full' />
						{type == 'password' && editMode && <div onClick={() => setShowPassword(!showPassword)} className={`fadeIn absolute cursor-pointer select-none right-10 text-xl ${!showPassword ? 'fas fa-eye ' : 'fas fa-eye-slash'} hover-text-green transition`}></div>}
					</div>
				</div>
			</div>
			<div className='mb-1 text-left pl-2 text-sm self-start text-gray-700 '>
				{editMode && hidden  ?
					type == 'username' ?
						<div className='pt-2 font-bold fadeIn'>
							<p>El <span className='text-green'>usuario</span> debe contener: </p>
							<p className='pl-2'><span className='text-green'>*</span> Al menos 6 dígitos </p>
							<p className='pl-2'><span className='text-green'>*</span> Al menos una letra minúscula </p>
							<p className='pl-2'><span className='text-green'>*</span> Solo letras y números </p>

						</div>
					: type == 'password' ?
						<div className='pt-2 font-bold fadeIn'>
							<p>La <span className='text-green'>contraseña</span> debe contener: </p>
							<p className='pl-2'><span className='text-green'>*</span> Al menos 6 dígitos </p>
							<p className='pl-2'><span className='text-green'>*</span> Al menos una letra minúscula </p>
							<p className='pl-2'><span className='text-green'>*</span> Al menos un número decimal </p>
						</div>
					: type == 'email' ?
						<div className='pt-2 font-bold fadeIn'>
							<p className='pl-2'><span className='text-green'>*</span> Debe ser un <span className='text-green'>email</span> válido  </p>
						</div>
					:
						''
				:
					''
				}
			</div>
			{
				loading ? 
					<div className='mt-2 '>
						<i class="text-3xl fas fa-spinner text-green animate-spin"></i>
					</div>
				:	editMode ?
					<div className='flex flex-col items-center justify-center'>
						{!editMode || error &&
						<div className={`fadeIn font-bold px-2 border-l-4 border-b-4 border-red-400 bg-gray-100 rounded-xl mt-2 text-center text-gray-700  text-sm py-2`}>
							<p className=''>{submitMessage}</p>
						</div>
						}
						<div 
							onClick={handleSave}
							className={`${hidden && 'hidden'} fadeIn mt-2 border-4 border-green p-1 rounded hover:text-gray-200  hover-bg-green max-w-min text-gray-600 items-center justify-center transition hover-text-green press-animation flex  select-none cursor-pointer`}
						>
							<i className='text-xl  far fa-save flex items-center  font-bold	 ' ></i>
							<p className='pl-1 font-bold uppercase '>Guardar</p>
						</div>
					</div>
				: submitMessage &&
					<div className='fadeIn px-2 border-l-4 border-b-4 border-green-400 bg-gray-100 rounded-xl mt-2 text-center text-gray-700 font-bold text-sm py-2'>
						<p className=''>{submitMessage}</p>
					</div>
			}
		</div>
  );
}